import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

const reports_server = axios.create({
    baseURL: process.env.REACT_APP_API_REPORTS_URL,
    headers: { 'Content-Type': "application/json" },
});

export class OrdersFetchAPI {
    static async getOrderByCode(order_code, by_qr, branchoffice_id) {
        const res = await server(`/order/${order_code}/code/${by_qr}/branchoffice/${branchoffice_id}`, {
            method: "get"
        });
        return res
    }

    static async getsOrdersByStatusByType(business_code, branchoffice_code, status_id, token) {
        const res = await server(`/orders/${business_code}/business/${branchoffice_code}/branch/${status_id}/status`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getOrderById(order_id, token) {
        const res = await server(`/order/${order_id}`, {
            method: 'get',
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async updateOrderStatus(state_id, order_id, groups, token) {
        console.log(groups)
        const res = await server(`/order/${order_id}`, {
            method: 'put',
            data: { "state_id": state_id, "groups": groups },
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getTransactionsByBranchOffice(business_code, branchoffice_code, minDate, maxDate, token) {
        const res = await reports_server(`/branch/orders/${branchoffice_code}/${business_code}/${minDate}/${maxDate}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getTransactionsByBranchOfficePaymentMethod(business_code, branchoffice_code, minDate, maxDate, paymentmethod_id, token) {
        const res = await reports_server(`/branch/paymentmethod/orders/${branchoffice_code}/${business_code}/${minDate}/${maxDate}/${paymentmethod_id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get temporal orders by code 
    static async getTempOrderByCode(order_code, business_code, branchoffice_code, token) {
        const res = await server(`/temp/order/${order_code}/${business_code}/${branchoffice_code}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getTemporalOrdersByBranch(business_code, branchoffice_code, minDate, maxDate, token) {
        const res = await server(`/temp/branch/orders/${branchoffice_code}/${business_code}/${minDate}/${maxDate}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get all the report info 
    static async getTransactionsByBranchOfficePaymentMethodbyUser(business_code, branchoffice_code, minDate, maxDate, paymentmethod_id, user_id, token, devolution, tips) {
        let final_url_string = `/branch/paymentmethod/orders/user/${branchoffice_code}/${business_code}/${minDate}/${maxDate}/${paymentmethod_id}/${user_id}`;
        //If devolution is true we add it to the url
        if (devolution) {
            final_url_string = final_url_string + `?devolutions=${devolution}`
        }
        //If tips is true we add it to the url
        if (tips) {
            //We check if the url has a query string already
            if (devolution) {
                final_url_string = final_url_string + `&justtips=${tips}`
            } else {
                final_url_string = final_url_string + `?justtips=${tips}`
            }
        }
        //We make the request
        const res = await reports_server(final_url_string, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get all the report info 
    static async getTransactionsByBranchOfficePaymentMethodbyTable(business_code, branchoffice_code, minDate, maxDate, payment_method_id, user_id, machine_id, table_id, token, client_id) {
        const res = await reports_server(`/branch/paymentmethod/orders/user/${branchoffice_code}/${business_code}/${minDate}/${maxDate}/${payment_method_id}/${user_id}/${machine_id}/${table_id}/${client_id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getTransactionsByBranchOfficePaymentMethodbyMachines(business_code, branchoffice_code, minDate, maxDate, paymentmethod_id, user_id, machine_id, token) {
        const res = await reports_server(`/branch/paymentmethod/orders/user/${branchoffice_code}/${business_code}/${minDate}/${maxDate}/${paymentmethod_id}/${user_id}/${machine_id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get temporal orders by code 
    static async updateTempOrder(data, token) {
        const res = await server(`/temp/order`, {
            method: "put",
            data,
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Funtion to get sales reports 
    static async getSalesReport(minDate, maxDate, branchoffice_id, machine_id, token) {
        const res = await server(`/sales_report/${minDate}/${maxDate}/${branchoffice_id}/${machine_id}/report`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getProductSalesReport(minDate, maxDate, branchoffice_id, machine_id, token) {
        const res = await server(`/product_report/${minDate}/${maxDate}/${branchoffice_id}/${machine_id}/report`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get temporal orders by machine 
    static async getTempOrderByMachine(machine_code, business_code, branch_code, isdiscount, token) {
        const res = await server(`/temp/machine/order/${machine_code}/${business_code}/${branch_code}/${isdiscount}`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get the cashier orders 
    static async getOrdersByUser(business_code, user_code, minDate, maxDate) {
        const res = await server(`/user/${user_code}/${minDate}/${maxDate}/${business_code}/business/orders`, {
            method: "get"
        });
        return res
    }

    static async getNumberOrder(branchoffice_id, token) {
        const res = await server(`/last_order/${branchoffice_id}`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }


    static async getOrderToPrint(transaction_code, token) {
        const res = await server(`/print/order/${transaction_code}`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }


    static async getOrderByStatusBusinessBranchOrderTypeDate(order_type, business_code, branchoffice_code, status_id, minDate, maxDate, token) {
        let final_string_order_type_status = "order_type=" + order_type.join("&order_type=") + "&status_id=" + status_id.join("&status_id=")
        const res = await server(`/orders/${business_code}/business/${branchoffice_code}/branch/${minDate}/minDate/${maxDate}/maxDate/status/order_type?${final_string_order_type_status}`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }



    static async getTransactionByCode(transaction_code, business_code, branch_code, token) {
        const res = await server(`/order/${transaction_code}/transaction_code/${branch_code}/branchoffice/${business_code}/business`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }
}




