import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
//Context
import UserContext from "../../context/UserContext";
//Api 
import { AuthFetchAPI } from '../../api/Auth';
import { UsersFetchAPI } from '../../api/Users';
import { BusinessFetchAPI } from '../../api/Business';
//Components 
import Swal from "sweetalert2";
//Img
import logo_sp from '../img/logos/icon-black.png';
//CSS
import "./login.css";

const Login = () => {
    //States 
    const [businessData, setBusinessData] = useState({
        name: '',
        id: 0
    });
    const [eventListener, setEventListener] = useState(0)
    const [selectedBranch, setSelectedBranch] = useState();
    const [users, setUsers] = useState([]);
    //Context 
    const { setUserData } = useContext(UserContext);
    //Params
    const { business_code, branchoffice_code } = useParams();
    //Navigation
    const navigate = useNavigate();
    //Location page 
    const url_path = useLocation();

    //Function to get the initial data 
    const getInitialData = async (business_code) => {
        //Variables
        let response_business = '';
        try {
            //We get the business
            response_business = await BusinessFetchAPI.getBusinessByCode(business_code)
            //We set the business data 
            setBusinessData(response_business.data.business)
        } catch (err) {
            //If we get some error we redirect to a no mactch page 
            navigate('/nomatch')
        }

        //We set the handler to force to charge the state of the business and branchoffice 
        setEventListener(1)
    }

    //Function to get the users of a branchoffice or a business depending the role 
    const getUsers = async (branchoffice_id, branch_code) => {
        try {
            //Variables 
            let user_role_id;
            let response;
            //We check what type of login
            if (url_path.pathname.includes('cashier')) {
                user_role_id = 4 //Cashier role 
            } else if (url_path.pathname.includes('dispatcher')) {
                user_role_id = 5 //Dispatcher role
            } else {
                user_role_id = 3; //Admin role 
            }

            //We check if the role is tha admin role to make the consult 
            if (user_role_id === 3) {
                //If the role is admin we just get the admin users by business 
                response = await UsersFetchAPI.getUsersByBusinessAndRole(businessData.id, [3, 6, 7, 8]);
            } else {
                //We get the users by branch and role 
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchoffice_id, user_role_id);
                setSelectedBranch(branch_code);
            }
            setUsers(response.data.users);

        } catch (err) {
            //If we get some error we set the users data empty 
            setUsers([])
            setSelectedBranch(null)
        }
    }

    //Event to login
    const submit = async (e) => {
        e.preventDefault();
        //We check if the user check one branch office 
        if ((url_path.pathname.includes('cashier') && selectedBranch !== null) || (url_path.pathname.includes('dispatcher') && selectedBranch !== null) || (!url_path.pathname.includes('dispatcher') || !url_path.pathname.includes('cashier') && selectedBranch === undefined || selectedBranch === null)) {
            const form = new FormData(e.target);
            let user_logged = Object.fromEntries(form.entries());
            user_logged.business_id = businessData.id;
            try {
                const response = await AuthFetchAPI.login(user_logged);
                //We get the token and user data 
                let token = response.data.token;
                let user = response.data.user;
                //We set the user info and the token access 
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('x-access-token', token);
                //We set the context of the user 
                setUserData({ token, user: user });

                //We check the role of the user and depending of it we redirect 
                if (user.role === 4) {
                    navigate(`/cashier/${business_code}/${selectedBranch}`)
                } else if (user.role === 5) {
                    navigate(`/orders_customers/${business_code}/${selectedBranch}`)
                } else if (user.role === 3 || user.role === 6 || user.role === 7) {
                    navigate(`/transactions/${business_code}`)
                } else if (user.role === 8) {
                    navigate(`/products/${business_code}`)
                }

            } catch (err) {
                Swal.fire({
                    icon: "error",
                    title: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            //If the user dont select any branch we throw an error
            Swal.fire({
                icon: "error",
                title: 'Seleccione una sucursal',
                showConfirmButton: false,
                timer: 1500,
            });
        }

    }

    //Event valid sesion of the machine
    /* const authValidSesion = () => {
         if (localStorage.getItem("x-access-machine-token") != "") {
             let business = JSON.parse(localStorage.getItem("business"))
             let branchoffice_info = JSON.parse(localStorage.getItem("branchoffice"))
             navigate(`/payments/${business.code}/${branchoffice_info.code}`)
         }
     }*/

    //Hook to get data when the page is loaded
    useEffect(() => {
        //We get the business by code 
        getInitialData(business_code);
        if (branchoffice_code === undefined) {
            getUsers(0, 0);
        }

    }, [eventListener]);

    //Function to list the users of branchoffice
    const listUsers = () => {
        let users_list;
        if (users !== undefined) {
            users_list = users.map((data) => (
                <>
                    <input type="radio" className="btn-check" name="email" id={data.user_id} value={data.email} />
                    <label className="btn btn-outline-primary border  
                    rounded-3 fw-semibold" for={data.user_id}>{data.user_name}</label>
                </>
            ))
        } else {
            users_list = ''
        }

        //We check the users list
        return (<>{users_list} </>)
    }

    const [showPassword, setShowPassword] = useState('')
    const toogglePasword = () => {
        setShowPassword(!showPassword);
    }


    useEffect(() => {

        let urlNow = window.location.href + ""
        let arrayParams = urlNow.split('/');
        console.log(arrayParams[3] + " " + arrayParams[4]);

        if (arrayParams[3] == "machines" && arrayParams[4] == "login") {
            console.log("entre");

            if (localStorage.getItem("x-access-token") == "" || localStorage.getItem("x-access-token") == undefined || localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined || localStorage.getItem("user") == "" || localStorage.getItem("user") == undefined) {

            } else {

                let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
                let machine = JSON.parse(localStorage.getItem('machine'));
                let business = JSON.parse(localStorage.getItem("business"))

                //console.log(`/kiosk/${business.code}/${branchoffice.code}`);
                navigate(`/payments/${business.code}/${branchoffice.code}`)
            }
        }

    }, []);

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    // Opcional: Si quieres actualizar el año automáticamente cada año, puedes usar useEffect.
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 60000); // Actualiza cada minuto, ajusta según necesites
        return () => clearInterval(interval);
    }, []);

    return (
        <>


            <section className="py-0 py-md-0 py-xl-4">

                <div className="container">
                    <div className="top-container-sp bg-login">
                        <div className='container  d-flex justify-content-start'>
                            <div className='scale-up-top-left-sp'>
                                <span className='text-dark'>¡Bienvenido a </span>
                                <span className='text-color-sp'>Smart Pay </span> 😄!
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4 align-items-center ">
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 info-display    position-relative order-xxl-0 order-1">
                            <img src="https://images.unsplash.com/photo-1678500876674-3a50ec26a1e6?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                loading='lazy'
                                className='bg-login bg-filter scale-up-top-left-sp'
                                alt="" />
                            <div className="d-flex justify-content-center info-bussiness p-3">
                                <div>
                                    {/* <img
                                        className="img-logo-brand  img-fluid rounded"
                                        src={logo_sp}
                                        alt="Logo Smart Pay "
                                        loading="lazy"
                                    /> */}

                                    <h2 className="h1 mb-4 fw-bold text-white">Smart Pay</h2>
                                    <p className="lead mb-5 fw-semibold text-white ">Transformando la gestión y  eficiencia de tu negocio,
                                        impulsando su crecimiento a través de soluciones innovadoras.</p>
                                    <div className="text-endx">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-6 justify-content-center order-xxl-1 order-0">
                            <div className="border-0 rounded-4">
                                <div className=" p-0">
                                    <div className='main-login-sp '>
                                        <section className="form-signin-sp bg-white pt-4  ">
                                            <div className="d-flex justify-content-center  justify-content-lg-center">
                                                <img
                                                    className="img-logo-brand "
                                                    src={logo_sp}
                                                    alt="Logo Smart Pay "
                                                />

                                            </div>
                                            <div>

                                                <h2 className="title-main-login  text-center ">Iniciar Sesión</h2>
                                                <div className='d-flex justify-content-center'>
                                                    <h5 className="text-dark-50 text-center opacity-50 rounded-3 border px-2">
                                                        <i className="uil uil-store"></i>
                                                        {businessData.name}
                                                    </h5>
                                                </div>

                                                <form onSubmit={submit} noValidate>
                                                    <label className="form-label fw-bold">
                                                        <i className="uil uil-store"></i> Usuarios
                                                    </label>
                                                    <div className='login-wrapper  no-scroll'>
                                                        <div className='d-flex flex-column gap-1 p-2' >
                                                            {listUsers()}
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <label className='form-label fw-bold' htmlFor="floatingPassword">{!showPassword ? <i className="uil uil-lock-alt"></i> : <i className="uil uil-lock-open-alt"></i>} Contraseña</label>

                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="form-control rounded-3 border-1 text-muted"
                                                            id="password"
                                                            name='pass'
                                                            placeholder="Ingresar contraseña"
                                                            required
                                                            spellcheck="false"
                                                        />
                                                    </div>



                                                    <div className="form-check text-start my-3">
                                                        <input className="form-check-input" type="checkbox"
                                                            value="remember-me" id="flexCheckDefault"
                                                            onChange={toogglePasword}
                                                        />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Mostrar Contraseña
                                                        </label>
                                                    </div>
                                                    <button className="control" type="submit">
                                                        <span className='rotated-icon'>
                                                            <i className="uil uil-message "></i>
                                                        </span>
                                                        Ingresar
                                                    </button>
                                                </form>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-center  p-lg-3 mt-3 opacity-50'>

                                                <span>
                                                    © {currentYear} Smart Pay
                                                </span>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login