import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../../api/BranchOffices';
import { UsersFetchAPI } from '../../../api/Users';
import { OrdersFetchAPI } from '../../../api/Orders';

// External Componets
import moment from 'moment-timezone';
import Select from 'react-select';
//Internal components 
import ReportTable from '../table/ReportTable';
import SuccessAlert from '../../Alerts/SuccessAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//External components 
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
//Utils 
import { checkUserPermission } from '../../utils/CheckUserPermitions';
import { RenderIf } from '../../utils/RenderIf';
pdfMake.vfs = pdfFonts.pdfMake.vfs


const PaymentMethodReports = () => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');

    //States 
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [userBusiness, setUserBusiness] = useState({
        id: 0
    });
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    //States of search select 
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [cashiers, setCashiers] = useState([]);
    const [machineCase, setMachineCase] = useState(false);
    const [vendingCase, setVendingCase] = useState(false);
    const [url, setUrl] = useState(null);

    const [data, setReportData] = useState({
        totalTaxSales: 0,
        subTotalSales: 0,
        totalSales: 0,
        totalDiscounts: 0,
        totalDevolutions: 0,
        business: '',
        user: '',
        machines: [],
        paymenthods: [],
        createdAt: '',
        branchofficeDate_orders: [],
        orders: []
    });

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            console.log(response)
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
            setCashiers([])
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Functio to get cashier users depending of the branch selected
    const getCashiers = async (branchSelected) => {
        let response;
        try {
            //We get the users depending the branch selected 
            if (branchSelected === 0) {
                //If the branch selected is 0 it means that we want the users of the business (all)
                //We get the user cashiers of the business
                response = await UsersFetchAPI.getUsersByBusinessAndRole(userBusiness.id, [4]);
            } else {
                //We get the user cashiers of the branch selected
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchSelected, 4);
            }
            setCashiers(response.data.users)
        } catch (err) {
            setCashiers([])
        }
    }

    //The default option of the select input 
    const defaultOption = [{ label: 'Seleccione...', value: 0 }, { label: 'Terminales de autoservicio', value: 1 }, { label: 'Vending', value: 'v' }];

    //Get users (cashiers)
    const usersList = cashiers.map((user) => ({
        label: user.user_name + ' ' + user.lastName + ': ' + user.idCard,
        value: user.user_id
    }));

    //Function to get the report of the branch office 
    const getReport = async (dateMin, dateMax) => {
        let response;
        try {
            //We show a loading alert 
            LoadingAlert();
            //We check if the machine case is true (on the select input we select the machine option)
            if (machineCase === true) {
                //We get the transaction info with the machine selected
                response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyMachines(business_code, selectedBranch.code, dateMin, dateMax, 0, user.id, 0, token);
            } else {
                //We get the transactions depending of the user selected
                if (selectedUserOption === null) {
                    //We get the transaction general info with the admin user logged 
                    response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch.code, dateMin, dateMax, 0, user.id, token);
                } else if (machineCase === false && vendingCase === true) {
                    //We get the transaction info with the machine selected
                    response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyMachines(business_code, selectedBranch.code, dateMin, dateMax, 0, user.id, 'v', token);
                }
                else {
                    //We get the transaction info with the user selected
                    response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch.code, dateMin, dateMax, 0, selectedUserOption.value, token);
                }
            }
            //We set the info 
            setReportData({
                totalTaxSales: response.data.analitics.totalTaxSales,
                subTotalSales: response.data.analitics.subTotalSales,
                totalSales: response.data.analitics.totalSales,
                totalDiscounts: response.data.analitics.totalDiscounts,
                business: response.data.analitics.business,
                branchoffice: response.data.analitics.branchoffice,
                machines: response.data.analitics.machines,
                paymenthods: response.data.analitics.paymenthods,
                createdAt: response.data.analitics.createdAt,
                branchofficeDate_orders: response.data.analitics.branchofficeDate_orders,
                totalDevolutions: response.data.analitics.totalDevolutions,
                user: response.data.analitics.user_selected,
                orders: response.data.orders
            });

            //We show a success alert
            SuccessAlert();
        } catch (err) {
            //We show a error alert 
            ErrorAlert('No tiene información')
            console.log(err)
        }
    }

    //Event to detect the user selected on search select 
    const handleChangeUsers = (option) => {
        //We check if the option is null 
        if (option.value === 0) {
            setSelectedUserOption(null);
            setMachineCase(false);
            setVendingCase(false)
        } else if (option.value === 1) {
            setMachineCase(true);
            setVendingCase(false)
            setSelectedUserOption(option);
        } else if (option.value === 'v') {
            setMachineCase(false);
            setVendingCase(true);
            setSelectedUserOption(option)
        }
        else {
            setSelectedUserOption(option);
            setMachineCase(false);
            setVendingCase(false);
        }
    };


    //Function to populate the orders details by createdAt date 
    const tableBranchOfficeOrders = () => {
        let branchOfficeOrdersData = data.branchofficeDate_orders;
        let rows = [];
        //We construc the table 
        for (let i = 0; i < branchOfficeOrdersData.length; i++) {
            let branchOfficeOrdersData_info = branchOfficeOrdersData[i];
            {/* Encabezado de la tabla de Productos  */ }
            rows.push(
                // ?Title Of Table
                {
                    text: 'Fecha de las ordenes: ' + branchOfficeOrdersData_info.branchOfficeDate,
                    style: 'subheaderRight',
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [140, '*', 150],
                        headerRows: 1,
                        // dontBreakRows: true,
                        // keepWithHeaderRows: 1,
                        body: [
                            //Values of Reports 
                            [
                                //Tables Headers 
                                {
                                    text: 'N°.Orden',
                                    style: 'tableHeader',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: 'Método de Pago',
                                    style: 'tableHeader',
                                    border: [false, false, false, false],
                                },
                                {
                                    text: 'Total',
                                    style: 'tableHeader',
                                    border: [false, false, false, false],
                                }
                            ],
                        ]
                    }, layout: 'lightHorizontalLines'
                },
            );
            //We get the final row position to get the table of the orders 
            let final_row_position = rows.length;
            //We construct the rows of the table 
            for (let j = 0; j < branchOfficeOrdersData_info.orders.length; j++) {
                let order = branchOfficeOrdersData_info.orders[j];
                let data_row = [
                    //Column 1
                    {
                        border: [false, false, false, true],
                        fillColor: '#eeffee',
                        text: 'Orden:# ' + order.transaction_code,
                    },

                    //Column 2
                    {
                        border: [false, false, false, true],
                        fillColor: '#eeffee',
                        text: order.devolution_order_reference !== undefined ? 'Devolución de ' + order.paymenthod + ' de la orden #' + order.devolution_order_reference.code : order.paymenthod,
                    },

                    //Column 3
                    {
                        border: [false, false, false, true],
                        fillColor: '#eeffee',
                        text: ' $ ' + order.total.toFixed(2),
                    },

                ]
                {/* Filas de la tabla completa de ordenes */ }
                rows[final_row_position - 1].table.body.push(data_row);
            }
        }
        return rows

    }


    //Function to populate the payment methods table rows 
    const tablePaymentMethods = () => {
        let paymentMethodsData = data.paymenthods
        //We construct the rows 
        let rows = [];
        //We populate the payment methods 
        for (let i = 0; i < paymentMethodsData.length; i++) {
            let paymenmethod = paymentMethodsData[i];
            let payment_row = [
                //Column 1
                paymenmethod.name,
                //Column 2
                ' $ ' + paymenmethod.total.toFixed(2)
            ]

            rows.push(payment_row)
        }

        let table = {
            style: 'tableExample',
            table: {
                widths: [200, '*'],
                headerRows: 1,
                // dontBreakRows: true,
                // keepWithHeaderRows: 1,
                body: [
                    [

                        //Tables Headers 
                        { text: 'Formas de Pago', style: 'tableHeader' },
                        { text: 'Total', style: 'tableHeader' }],
                    ...rows
                ]
            }
        }
        //We return the table 
        return table

    }


    //We create the pdf 
    const createPdf = () => {
        let machines = []
        let date = ''
        let hour = ''

        //validationof fields
        if (data.length !== 0) {
            machines = data.machines.join(', ');
            date = data.createdAt.split('T')[0];
            hour = data.createdAt.split('T')[1];
        }
        //Information
        let name_bussiness = data.business
        let name_branch = data.branchoffice


        const docDefinition = {

            pageSize: 'A4',
            pageMargins: [23, 10, 23, 5],

            content: [
                //?IMG
                {
                    "image": "logo-smartpay",
                    width: 100,
                },
                //?Title Report 
                {
                    stack: [
                        'Reportes de Ventas',
                    ],
                    style: 'header'
                },

                //Information Bussiness
                //? 1 --------------------------
                {
                    text: [
                        { text: 'Empresa:', style: 'subheader' },
                        name_bussiness
                    ], margin: [0, 20, 0, 5]
                },
                //? 2 --------------------------       
                {
                    text: [
                        {
                            text: name_branch !== '' ? 'Sucursal:' : '',
                            style: 'subheader'
                        },
                        name_branch !== '' ? name_branch : '',
                    ], margin: [0, 0, 0, 5]
                },
                //? 3 --------------------------
                {
                    text: [
                        {
                            text: data.user.name !== '' ? 'Cajera/o : ' + data.user.name : '',
                            style: 'subheader'
                        },
                    ], margin: [0, 0, 0, 5]
                },

                // {
                //     text: data.user.name !== '' ? 'Cajera/o : ' + data.user.name : '',
                //     style: 'subheader'
                // },
                //? 4 --------------------------
                {
                    text: [
                        {
                            text: 'Máquinas:',
                            style: 'subheader',
                        },
                        machines
                    ], margin: [0, 0, 0, 5]
                },

                //? 5 --------------------------           
                {
                    text: [
                        {
                            text: 'Fecha:',
                            style: 'subheader',
                            margin: [0, 0, 0, 10]
                        },
                        date
                    ], margin: [0, 0, 0, 5]
                },

                //? 6 --------------------------
                {
                    text: [
                        {
                            text: 'Hora: ',
                            style: 'subheader',
                            margin: [0, 0, 0, 20]
                        },
                        hour
                    ]
                },


                //?Title Of Table
                {
                    text: 'Ordenes',
                    style: 'titles', margin: [0, 15, 0, 0]
                },
                //Title Of Table
                tableBranchOfficeOrders(),

                //Title Of Table
                {
                    text: 'Métodos de Pago',
                    style: 'titles'
                },


                //Pays methods Table of Contents
                tablePaymentMethods(),

                //Title Of Table
                {
                    text: 'Montos totales',
                    style: 'titles'
                },


                //Totals 
                {
                    style: 'tableExample',
                    table: {
                        widths: [200, '*'],
                        headerRows: 1,
                        // dontBreakRows: true,
                        // keepWithHeaderRows: 1,
                        body: [
                            [

                                //Tables Headers 
                                { text: 'Montos', style: 'tableHeader3' },
                                { text: 'Total', style: 'tableHeader3' }],


                            //Values of Reports 
                            [
                                //Column 1
                                'Impuestos: ',
                                //Column 2
                                ' $ ' + data.totalTaxSales.toFixed(2),
                            ],

                            [
                                //Column 1
                                'Subtotal: ',
                                //Column 2
                                ' $ ' + data.subTotalSales.toFixed(2),
                            ],

                            [
                                //Column 1
                                'Total de ventas : ',
                                //Column 2
                                ' $ ' + data.totalSales.toFixed(2),
                            ],
                        ]
                    }, layout: 'lightHorizontalLines'
                },

                //Title Of Table
                {
                    text: 'Información adicional',
                    style: 'titles'
                },


                //Adicional info 
                {
                    style: 'tableExample',
                    table: {
                        widths: [200, '*'],
                        headerRows: 1,
                        // dontBreakRows: true,
                        // keepWithHeaderRows: 1,
                        body: [
                            [

                                //Tables Headers 
                                { text: 'Dato', style: 'tableHeader3' },
                                { text: 'Total', style: 'tableHeader3' }],


                            //Values of Reports 
                            [
                                //Column 1
                                'Devoluciones totales realizadas: ',
                                //Column 2
                                ' $ ' + data.totalDevolutions.toFixed(2),
                            ],
                            //Values of Reports 
                            [
                                //Column 1
                                'Descuentos totales: ',
                                //Column 2
                                ' $ ' + data.totalDiscounts.toFixed(2),
                            ],
                        ]
                    }, layout: 'lightHorizontalLines'
                },
            ],

            images: {
                "logo-smartpay": "https://res.cloudinary.com/dk3x6ubyi/image/upload/v1682698324/hi2vihfdtgg98ybwbxnn.png"
            },

            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 5, 0, 10],
                },
                titles: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center',
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'left',
                },
                subheaderRight: {
                    fontSize: 12,
                    alignment: 'right',
                    color: '#A8A8A8'
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    fillColor: '#43CD3B',
                    fontSize: 13,
                    color: 'white',
                    bold: true,
                },

                tableHeader2: {
                    fillColor: '#26BABF',
                    fontSize: 13,
                    color: 'white',
                    bold: true,
                },

                tableHeader3: {
                    fillColor: '#266DBF',
                    fontSize: 13,
                    color: 'white',
                    bold: true,
                }


            }

        }


        const pdfGenerator = pdfMake.createPdf(docDefinition);
        pdfGenerator.getBlob((blob) => {
            const url = URL.createObjectURL(blob);
            setUrl(url)

        })

        pdfGenerator.download();
    }

    //Function to populate the payment methods cards totals
    const paymentMethodCards = () => {
        const paymentdata = data.paymenthods.filter((item) => {
              // Verificar permisos específicos para cada método de pago
              const permissionKey = `view_total_${item.name.toLowerCase()}`; // Generar la clave de permiso dinámica
              return checkUserPermission(permissionKey, user.permissions) !== -1;
            });

        const cards = paymentdata.map((data) => (
            <>
                <div className="col-xl-3 col-md-6 col-sm-6 col-6">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className=" d-flex justify-content-between">
                                    <div className="media-body text-left">
                                        <h2 className="display-6 text-dark fw-bold">{parseFloat(data.total).toFixed(2)} <span>$</span></h2>
                                        <span className='fw-semibold'>{data.name}</span>
                                    </div>
                                    <div className="align-self-center">
                                        {(data.name.toLowerCase() == 'visa') ?
                                            <i className="fa-brands fa-cc-visa text-primary fa-3x"></i> :
                                            <>{(data.name.toLowerCase() == 'yappy') ?
                                                <i className="fa-solid fa-comments text-secondary fa-3x"></i> :
                                                <>{(data.name.toLowerCase() == 'mastercard') ?
                                                    <i className="fa-brands fa-cc-mastercard text-warning fa-3x"></i> :
                                                    <>{(data.name.toLowerCase() == 'clave') ?
                                                        <i className="fa-solid fa-credit-card text-danger fa-3x"></i> :
                                                        <>{(data.name.toLowerCase() == 'efectivo') ?
                                                            <i className="fa-solid fa-sack-dollar text-success fa-3x"></i> : <> {
                                                                (data.name.toLowerCase() == 'amex') ?
                                                                    <i className="fa-brands fa-cc-amex text-info fa-3x"></i> :
                                                                    <><i className="fa-solid fa-money-bill fa-3x"></i></>}
                                                            </>}
                                                        </>}
                                                    </>}
                                                </>}
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ));
        return cards
    }


    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We set the business user 
        setUserBusiness(user.business);
    }, []);

    //Hook to load the users depending the selection of branch 
    useEffect(() => {
        //We get the users 
        getCashiers(selectedBranch.id)
    }, [selectedBranch]);


    return (
        <div className='mx-lg-2 mx-1'>
            <h4 className='fw-bold text-primary mt-2'>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 border-sp border">
                <div className="col-sm-12 col-md-4 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="input_from" className='fw-bold'>Sucursal</label>
                        <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                            <option value={0}>Mostrar todos</option>
                            {branchList()}
                        </select>
                    </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="input_from" className='fw-bold'>Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="form-control" />
                    </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="input_from" className='fw-bold'>Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="form-control" />
                    </div>
                </div>
                <div className="col-12 col-md-12 col-lg-3">
                    <label htmlFor="input_from" className='fw-bold'>Cajeros (opcional)</label>
                    <Select
                        className='w-100'
                        value={selectedUserOption}
                        options={[defaultOption[0], defaultOption[1], defaultOption[2], ...usersList]}
                        onChange={handleChangeUsers}
                    />

                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e => getReport(initialDate, maxDate)} className="btn btn-success px-5">Aceptar</button>
                </div>
            </div>
            <div className="d-lg-flex justify-content-lg-between mt-3">
                <h4 className="fw-bold text-primary">Reporte de ventas por metodo de pago  </h4>
                <div className="d-flex justify-content-between mt-3 mb-2 gap-2">
                    <a href={`${process.env.REACT_APP_API_URL}/excel/paymentmethodsales/${initialDate}/${maxDate}/${selectedBranch.id}/0/${userBusiness.id}/download`}
                        className="btn btn-outline-success">
                        <i className="fa-solid fa-file-excel me-2"></i>
                        Descargar Excel
                    </a>
                    <button className='btn btn-outline-danger' onClick={createPdf}>
                        <i className="fa-solid fa-file-pdf me-2"></i>
                        <span >Descargar PDF</span>
                    </button>
                </div>
            </div>

            {/**Totales por metodo de pago */}
            <div className="mt-lg-3 ">
                <div className="row mt-2 border-sp pb-4 bg-white">
                    <div className="main-content">
                        <div className="row justify-content-md-center ">
                            {paymentMethodCards()}
                        </div>
                    </div>
                </div>
            </div>

            {/**Table */}
            <ReportTable
                table_name="Tabla de ventas deatalladas de metodos de pago"
                table_headers={["Fecha", "Hora", "Orden", "Metodo de pago", "Total con propina", "Propina", "Total contable", "Subtotal", "Itbms", "Descuento", "Cufe"]}
                table_type="paymentmethod_report"
                table_data={data.orders}
            />
        </div>


    );
}

export default PaymentMethodReports;