import React, { useState, useMemo } from 'react';
import { Form } from 'react-bootstrap';
//Icons
import { FaEye, FaRegTrashAlt } from 'react-icons/fa'
//Utils 
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
//IMG
import defaultImg from '../../assets/img/default.png';

const Table = ({ table_name, table_data, table_headers, table_type, setShowModal, setEventHandler, setBuySelected, removeWebhook, setEditShowModal, setShowModalAsing, getUserDetails, removeUser, setSelectedMachine, setShowModalDetails, user, updateMachineStatus, getBranchofficesByNotification, getTableDetails, handleSwitchChange, generatePassword, removeData }) => {
    //States
    const [searchTerm, setSearchTerm] = useState('');

    //Function to populate the table headers
    const tableHeaders = () => {
        const headers = table_headers.map((header) => (
            <>
                <th>{header}</th>
            </>
        ));
        return headers;
    }

    //Filter the data by the term searched 
    const filteredData = useMemo(() => {
        if (searchTerm === '') {
            return table_data
        }
        return table_data.filter(data => {
            //We check the type of table 
            if (table_type === "general_report") {
                const transactionMatch = data.transaction_code.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const dateMatch = data.createdAt.toLowerCase().includes(searchTerm.toLowerCase());
                return transactionMatch || dateMatch
            } else if (table_type === "paymentmethod_report") {
                const transactionMatch = data.transaction_code.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const dateMatch = data.createdAt.toLowerCase().includes(searchTerm.toLowerCase());
                const paymentmethodMatch = data.paymenthod.toLowerCase().includes(searchTerm.toLocaleLowerCase());

                return transactionMatch || dateMatch || paymentmethodMatch
            } else if (table_type === "products_report") {
                const productMatch = data.group_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const totalAmountProductMatch = data.total_amount_selled.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase());

                return productMatch || totalAmountProductMatch
            } else if (table_type === 'buy_table') {
                const invoiceMatch = data.invoice.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const supplierMatch = data.supplier_name === null ? '' : data.supplier_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
                const dateMatch = data.createdAt.toLowerCase().includes(searchTerm.toLowerCase());

                return invoiceMatch || supplierMatch || dateMatch
            } else if (table_type === 'buy_sale_table') {
                const dateMatch = data.buy_sale_createdAt.toLowerCase().includes(searchTerm.toLowerCase());
                const productMatch = data.group_name.toLowerCase().includes(searchTerm.toLowerCase());

                return productMatch || dateMatch
            } else if (table_type === 'integration_param_table') {
                const name = data.param_name.toLowerCase().includes(searchTerm.toLowerCase());
                return name
            } else if (table_type === 'webhook_table') {
                const event = data.name.toLowerCase().includes(searchTerm.toLowerCase());
                return event
            } else if (table_type === 'user_table') {
                const nameMatch = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const user_code = data.user_code.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const roleMatch = data.role.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const emailMatch = data.email.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const idMatch = data.idCard.toLowerCase().includes(searchTerm.toLocaleLowerCase());

                return nameMatch || user_code || roleMatch || idMatch || emailMatch
            } else if (table_type === 'event_table') {
                const nameMatch = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const descriptionMatch = data.description.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                return nameMatch || descriptionMatch
            } else if (table_type === 'machine_table') {
                const nameMatch = data.machine_name !== null ? data.machine_name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) : null
                const codeMatch = data.code.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const machineType = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                return nameMatch || codeMatch || machineType
            } else if (table_type === "notification_types_table") {
                const nameMatch = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                return nameMatch
            } else if (table_type === "rooms_table") {
                const nameMatch = data.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const codeMatch = data.table_code.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                return nameMatch || codeMatch
            }
        });
    }, [searchTerm, table_data]);

    //Function to populate the table content 
    const tableContent = () => {
        //We check the type of table to render the content of the table 
        if (table_type === "general_report") {
            //We format the date and hour of the order
            const general_sales_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.createdAt.split('T')[1].split(':')[0] + ':' + content.createdAt.split('T')[1].split(':')[1] + ' ' + (content.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')} </td>
                        <td className='align-middle'> {content.transaction_code}</td>
                        <td className='align-middle'> {content.total === undefined ? "$ 0.00" : "$ " + content.total.toFixed(2)}</td>
                        <td className='align-middle'> {content.tax === undefined ? "$ 0.00" : "$ " + content.tax.toFixed(2)}</td>
                        <td className='align-middle'> {content.discount === undefined ? "$ 0.00" : "$ " + content.discount.toFixed(2)}</td>
                    </tr>
                </>
            ));

            return general_sales_content
        } else if (table_type === "paymentmethod_report") {
            //We format the date and hour of the order
            const paymentmethod_sales_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.createdAt.split('T')[1].split(':')[0] + ':' + content.createdAt.split('T')[1].split(':')[1] + ' ' + (content.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')} </td>
                        <td className='align-middle'> {content.transaction_code}</td>
                        <td className='align-middle'> {content.paymenthod}</td>
                        <td className='align-middle'> {content.total === undefined ? "$ 0.00" : "$ " + content.total.toFixed(2)}</td>
                    </tr>
                </>
            ));

            return paymentmethod_sales_content
        } else if (table_type === "products_report") {
            const product_sales_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.group_name}</td>
                        <td className='align-middle'>{content.total_amount_selled === undefined ? "0" : content.total_amount_selled}</td>
                        <td className='align-middle'>{content.total_selled === undefined ? "$ 0.00" : '$ ' + content.total_selled.toFixed(2)}</td>

                    </tr>
                </>
            ));

            return product_sales_content
        } else if (table_type === "buy_table") {
            const buy_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.is_void_buy === 1 ? 'Anulacion de compra' : 'Compra'} </td>
                        <td className='align-middle'>{content.supplier_name === null ? "No registrado" : content.supplier_name}</td>
                        <td className='align-middle'>{content.invoice}</td>
                        <td className='align-middle'>{'$ ' + content.total.toFixed(2)}</td>
                        <td className='align-middle'>
                            <button data-id={content.id} onClick={e => { setShowModal(true); setBuySelected(content.buy_id); setEventHandler(1); }} className="btn-danger-us btn text-secondary bg-btn-secundary ">
                                <FaEye />
                            </button>
                        </td>

                    </tr>
                </>
            ));
            return buy_content
        } else if (table_type === "buy_sale_table") {
            const buy_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.buy_sale_createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.group_name}</td>
                        <td className='align-middle'>{content.buy_sale_amount}</td>
                        <td className='align-middle'>{content.is_buy === 1 ? 'Compra' : 'Venta'}</td>

                    </tr>
                </>
            ));
            return buy_content
        } else if (table_type === 'integration_param_table') {
            const param_values = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.param_name}</td>
                        <td className='align-middle'>{content.value}</td>
                        <td className='align-middle'>{content.description}</td>
                    </tr>
                </>
            ));
            return param_values
        } else if (table_type === "webhook_table") {
            const param_values = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.name}</td>
                        <td className='align-middle'>{content.url}</td>
                        <td className='align-middle'>
                            <button data-id={content.id} onClick={e => { removeWebhook(content.id); }} className="btn-danger-us btn text-secondary bg-btn-secundary ">
                                <FaRegTrashAlt />
                            </button>
                        </td>
                    </tr>
                </>
            ));
            return param_values
        } else if (table_type === 'user_table') {
            const users_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>
                            <img
                                className='imgitemsList m-1'
                                src={content.img}
                                alt={content.name}
                            />
                        </td>
                        <td className='align-middle'>{content.name}</td>
                        <td className='align-middle'>{content.user_code}</td>
                        <td className='align-middle'>{content.idCard}</td>
                        <td className='align-middle'>{content.email}</td>
                        <td className='align-middle'>{content.role}</td>
                        <td className='align-middle'>

                            <button className='btn btn-outline-primary btn-sm border-0' role-id={content.role_id} user-id={content.user_id}
                                onClick={e => { setEditShowModal(true); getUserDetails(content.user_id) }}
                            >
                                <i className="uil uil-pen"></i>
                            </button>

                            <button className='btn btn-outline-primary btn-sm border-0' role-id={content.role_id} user-id={content.user_id} onClick={e => { setShowModalAsing(true); getUserDetails(content.user_id, 'asingModal') }}>
                                <i className="uil uil-list-ol"></i>
                            </button>

                            <button className='btn btn-outline-primary btn-sm border-0' role-id={content.role_id} user-id={content.user_id} onClick={e => { removeUser(content.user_id) }} >
                                <i className="uil uil-trash"></i>
                            </button>
                        </td>

                    </tr>
                </>
            ));

            return users_content
        } else if (table_type === 'event_table') {
            const events_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>{content.initialDate.split('T')[0]}</td>
                        <td className='align-middle'>{content.maxDate.split('T')[0]}</td>
                        <td className='align-middle'>{content.public}</td>
                    </tr>
                </>
            ));

            return events_content
        } else if (table_type === 'machine_table') {
            const machine_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='aling-middle'>
                            <RenderIf isTrue={!content.is_logged}>
                                <div className='badge bg-danger'>Inactiva</div>
                            </RenderIf>
                            <RenderIf isTrue={content.is_logged}>
                                <div className='badge bg-success'>Activa</div>
                            </RenderIf>
                        </td>
                        <td className='align-middle'>{content.machine_name !== null ? content.machine_name : "No registrado"}</td>
                        <td className='align-middle'>{content.name}</td>
                        <td className='align-middle'>{content.code}</td>
                        <td className='align-middle'>
                            <RenderIf isTrue={checkUserPermission("get_details_machine", user.permissions) !== -1}>
                                <button className='btn btn-outline-primary btn-sm border-0' onClick={() => { setSelectedMachine(content.machine_per_branch_id); setEventHandler(1); setShowModalDetails(true); }} >
                                    <i className="uil uil-eye"></i>
                                </button>
                            </RenderIf>
                            <RenderIf isTrue={checkUserPermission("update_login_machine_status", user.permissions) !== -1}>
                                <button className='btn btn-outline-primary btn-sm border-0' onClick={() => { setEventHandler(1); updateMachineStatus(content.code, false) }}>
                                    <i className="uil uil-sync"></i>
                                </button>
                            </RenderIf>

                        </td>
                    </tr>
                </>
            ));

            return machine_content
        } else if (table_type === "notification_types_table") {
            const notification_types_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='align-middle'>
                            <img
                                className='imgitemsList m-1'
                                src={content.secure_url === null ? defaultImg : content.secure_url}
                                alt={content.name}
                            />
                        </td>
                        <td className='align-middle'>{content.name}</td>
                        <td className='align-middle'>{content.description}</td>
                        <td className='align-middle'>


                            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => { setShowModal(true); getBranchofficesByNotification(content) }}>
                                <i className="uil uil-eye"></i>
                            </button>

                            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => { setEditShowModal(true); getBranchofficesByNotification(content) }}>
                                <i className="uil uil-pen"></i>
                            </button>

                            {/*      <button className='btn btn-outline-primary btn-sm border-0' >
                                <i className="uil uil-trash"></i>
                            </button> */}
                        </td>

                    </tr>
                </>
            ));

            return notification_types_content
        } else if (table_type === "rooms_table") {
            const rooms_content = filteredData.map((content, index) => (
                <>
                    <tr key={index}>
                        <td className='aling-middle'>
                            <Form.Switch type="switch" id={content.id} label="" checked={content.status === 1 ? true : false} onChange={handleSwitchChange} />
                        </td>
                        <td className='aling-middle'>
                            <RenderIf isTrue={content.status === 0}>
                                <div className='badge bg-danger'>Inactiva</div>
                            </RenderIf>
                            <RenderIf isTrue={content.status === 1}>
                                <div className='badge bg-success'>Activa</div>
                            </RenderIf>
                        </td>
                        <td className='align-middle'>{content.name}</td>
                        <td className='align-middle'>{content.table_code}</td>
                        <td className='align-middle'>{content.password}</td>
                        <td className='align-middle'>{content.client_name !== null ? content.client_name : "No asignado"}</td>
                        <td className='align-middle'>

                            <button className='btn btn-outline-primary btn-sm border-0' data-id={content.id} onClick={(e) => generatePassword(e)}>
                                <i className="uil  uil-key-skeleton-alt"></i>
                            </button>

                            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => { setEditShowModal(true); getTableDetails(content.id, "edit") }}>
                                <i className="uil uil-pen"></i>
                            </button>

                            <button className='btn btn-outline-primary btn-sm border-0'data-id={content.id} onClick={(e) => removeData(e)} >
                                <i className="uil uil-trash"></i>
                            </button>
                        </td>

                    </tr>
                </>
            ));

            return rooms_content
        }
    }

    return (
        <>
            {/**Tabla de detalles */}
            <div className='bg-white  mb-5 mt-4'>

                <div className="row  mx-1 mt-3 ">
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className='fs-4 fw-bold text-left text-primary'>
                                {table_name}
                            </h4>
                        </div>
                    </div>

                    <div className="col-md-4  offset-md-4 ">
                        <div className="w-auto  d-flex justify-content-end mt-1">
                            <div className="row  g-3 align-content-center align-items-center">
                                <div className="col-auto">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese termino"
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-1 mt-3">
                    <div className=" border rounded table-responsive">
                        <table className='table table-hover table-sm' >
                            <thead className='table-dark'>
                                <tr>
                                    {tableHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Table;
