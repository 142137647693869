import React, { useEffect, useState } from 'react';

//Internal Components
import Navbar from '../NavBar/Navbar';
import GeneralReport from './tabs/GeneralReports';
//Components 
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import PaymentMethodReports from './tabs/PaymentMethodReports';
import ProductSalesReport from './tabs/ProductSalesReport';
import BuysAndSalesReport from './tabs/BuysAndSalesReport';
import PreAccountReport from './tabs/PreAccountReport';
import DiscountsReport from './tabs/DiscountsReport';
import ApprovedReturnsReport from './tabs/ApprovedReturnsReport';
import TipsReport from './tabs/TipsReport';
//CSS
import "./reports.css"
//Utils
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';


const ReportStore = () => {
    //We get the user data 
    let user = localStorage.getItem('user') !== '' || localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : '';
   return (
        <>
            <div className='sp-grid bg-white no-scroll position-relative g-0'>
                <div className=' fixed-column'>
                    <Navbar titlePage="Informes" />
                </div>
                <div className='other-column '>
                </div>
                {/** Main container */}

                <main className="m-2">
                    {/** Header */}
                    <header className='banner-reports d-flex justify-content-lg-between align-items-center mb-4 banner-sp rounded-4 py-2 px-3'>
                        <div>
                            <div className='d-flex justify-content-lg-between align-items-center'>
                                <h2 className='banner-reports-title mb-0 pb-0'>
                                    Reportes
                                </h2>
                            </div>
                            <p className='m-0 p-0 banner-reports-description'>Visualización de datos clave para análisis.</p>
                        </div>
                    </header>
                    {/** End Header  */}
                    {/**Tabs */}
                    <Tab.Container defaultActiveKey={checkUserPermission("report_general", user.permissions) !== -1 ? "General" : "payments"}>
                        <Row>
                            <Col sm={12} >
                                <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                    {/* General Report */}
                                    <RenderIf isTrue={checkUserPermission("report_general", user.permissions) !== -1}>
                                        <li className="nav-sp-item"  >
                                            <Nav.Link eventKey="General" title="General">
                                                <i className="fa-solid fa-house me-1"></i>
                                                General
                                            </Nav.Link>
                                        </li>
                                    </RenderIf>

                                    <li className="nav-sp-item">
                                        <Nav.Link eventKey="combo"> </Nav.Link>
                                    </li>
                                    {/* Payments Report */}
                                    <RenderIf isTrue={checkUserPermission("report_payment_method", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="payments" >
                                                <i className="fa-solid fa-money-check-dollar  me-1"></i> Métodos de Pago
                                            </Nav.Link>
                                        </li>
                                    </RenderIf>
                                    {/* Products Report */}
                                    <RenderIf isTrue={checkUserPermission("report_products", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="products" ><i className="fa-solid fa-bottle-water  me-1"></i>
                                                Productos</Nav.Link>
                                        </li>
                                    </RenderIf>
                                    {/* Buy and Sales Report */}
                                    <RenderIf isTrue={checkUserPermission("report_entry_exit", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="buysales" ><i className="fa-solid uil-shopping-bag  me-1"></i>
                                                Entrada y Salida de Productos</Nav.Link>
                                        </li>
                                    </RenderIf>

                                    {/* PreAccount Report */}
                                    <RenderIf isTrue={checkUserPermission("report_deleted_pre_account", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="preaccount" ><i className="fa-solid uil-shopping-bag  me-1"></i>
                                                Pre-Cuentas Eliminadas</Nav.Link>
                                        </li>
                                    </RenderIf>

                                    {/* Discounts Report */}
                                    <RenderIf isTrue={checkUserPermission("report_approved_discount", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="discount" ><i className="fa-solid uil-shopping-bag  me-1"></i>
                                                Descuentos Aprobados</Nav.Link>
                                        </li>
                                    </RenderIf>
                                    {/** Devoluciones aprobadas */}
                                    <RenderIf isTrue={checkUserPermission("report_approved_returns", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="approvedreturns" ><i className="fa-solid uil-shopping-bag  me-1"></i>
                                                Devoluciones Aprobadas</Nav.Link>
                                        </li>
                                    </RenderIf>
                                    {/** Propinas */}
                                    <RenderIf isTrue={checkUserPermission("report_tips", user.permissions) !== -1}>
                                        <li className="nav-sp-item">
                                            <Nav.Link eventKey="tips" ><i className="fa-solid uil-shopping-bag  me-1"></i>
                                                Propinas</Nav.Link>
                                        </li>
                                    </RenderIf>
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="General">
                                        <div className=' m-0'>
                                            <GeneralReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="payments">
                                        <div className=' m-0'>
                                            <PaymentMethodReports  />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="products">
                                        <div className=' m-0'>
                                            <ProductSalesReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="buysales">
                                        <div className=' m-0'>
                                            <BuysAndSalesReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="preaccount">
                                        <div className=' m-0'>
                                            <PreAccountReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="discount">
                                        <div className=' m-0'>
                                            <DiscountsReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="approvedreturns">
                                    <div className=' m-0'>
                                            <ApprovedReturnsReport />
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tips">
                                        <div className=' m-0'>
                                            <TipsReport />
                                        </div>
                                    </Tab.Pane>


                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </main>

            </div>
        </>
    );
}

export default ReportStore;
